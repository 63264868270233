import "./WorkCardStyles.css"
import React from "react";


const WorkCard = (props) =>{ 


   
    const downloadFileAtURL = (url) => {
        fetch(url)
        .then((response)=>response.blob())
        .then((blob)=> {
            const blobURL = window.URL.createObjectURL(new Blob([blob]))
            const fileName = url.split("/").pop();
            const aTag = document.createElement("a");
            aTag.href = blobURL;
            aTag.setAttribute("download" , fileName);
            document.body.appendChild(aTag);
            aTag.click();
            aTag.remove();
        });

    };

    return (
       
        <div className="project-card">
  
        <img src={props.imgsrc} alt="image" />
        
        
        <h2 className="project-title">{props.title}</h2>
        <div className="pro-details">
            <p>{props.text}</p>
            
            <div className="pro-btns">
                <button className="btn" onClick={()=>(downloadFileAtURL(props.view))}>Download Zip File</button>
            </div>
        </div>
   

    </div>
    
    );
};

export default WorkCard;