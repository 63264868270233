import "./HeroImgStyles.css";
import {Link} from "react-router-dom"
import React from "react";
import IntroImg from "../assets/imageTwo.jpeg"
import "./animate.css";
import pic5 from "../assets/GradPic_XLargeCircle.png"

const HeroImg = () =>{ 
    return( 
        
    <div className="hero">
        <div className="mask">
            <img className="into-img"
            src={IntroImg} alt="IntroImg"/>

        </div>
       
        <div className="content">
            <h1 className="animate__animated animate__slideInLeft">Hi, Im Alex Reichel</h1>
            <p className="animate__animated animate__fadeInRight"> Software Engineer with a focus in Object Oriented programming while practicing the Agile/Scrum method</p>
                <div className="threeRow">
                    <div className="button1">
                    <Link to="/project"
                    className="btn">Projects
                    </Link>
                    </div>
                    <img className="circle" src={pic5} />
                    <div className="button2">
                    <Link to="/contact"
                    className="btn">Contact
                    </Link>
                    </div>
               
               
                </div>
        </div>
        </div>

    );
};

export default HeroImg