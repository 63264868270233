import "./WorkCardStyles.css"
import WorkCard from "./WorkCard";
import WorkCardData from "./WorkCardData";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import React from "react";
import pro1 from "../assets/proj1.avif"
import pro2 from "../assets/proj2.avif"
import pro3 from "../assets/proj3.avif"
import pic4 from "../assets/pic4.jpg"
import pic5 from "../assets/pic5.jpg"
import pic6 from "../assets/pic6.jpg"
const BINPACKING_ZIP_FILE_URL = "BinPackingGreedyAlgorithm.zip";

const Work = (props) => { 

  const downloadFileAtURL = (url) => {
    fetch(url)
    .then((response)=>response.blob())
    .then((blob)=> {
        const blobURL = window.URL.createObjectURL(new Blob([blob]))
        const fileName = url.split("/").pop();
        const aTag = document.createElement("a");
        aTag.href = blobURL;
        aTag.setAttribute("download" , fileName);
        document.body.appendChild(aTag);
        aTag.click();
        aTag.remove();
    });

};


    const sliderSettings = {

        dots: true, // Show navigation dots
        infinite: true, // Infinite loop
        speed: 500, // Transition speed
        slidesToShow: 3, // Number of slides to show at once
        slidesToScroll: 1, // Number of slides to scroll at once
        swipe: true,
       
       
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              swipe: true,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              swipe: true,
            },
          },
        ],
      };
    return(
       
       <div className="work-container">
       
           <div className="animate__animated animate__pulse animate__delay-4s">
            
            <div className="projectShowcase">
              
              
            <h1>Project Showcase</h1>
  
            <hr />
            <br />
           
            <div className="project1">
            <div className="animate__animated animate__slideInRight">
              <div className="projectImage">
              <img src={pro1} alt="image" />
              <div>
              <h1>Order Tracker</h1>
              <p>Python Flask application connected to a SQL-Alchemy database displayed as a store front for both sellers and buyers, users can create products while others can buy those products. Sellers are able to update the order status placed by users which can then be displayed and tracked via a user-friendly interface displayed on both user ends.</p>
              <br />
              <div className="pro-btns2">
                <button className="btn" onClick={()=>(downloadFileAtURL(BINPACKING_ZIP_FILE_URL))}>Download Zip File</button>
              </div>
            </div>
            </div>
            </div>
            </div>

            <div className="project2">
            <div className="animate__animated animate__slideInLeft">
            <div className="projectImage">
              <div>
              <h1>PDF 2 Excel</h1>
            <p>Python program with a user interface to translate words from PDF documents written in Spanish and extracted important data to then be displayed and formatted in automated Excel sheets using Python packages and APIs such as deep-translator, xlwings, xlsxWriter, and pdfminer</p>
            <br />
            <div className="pro-btns2">
                <button className="btn" onClick={()=>(downloadFileAtURL(BINPACKING_ZIP_FILE_URL))}>Download Zip File</button>
                
            </div>
            </div>
            <img src={pic5} alt="image" />
            </div>
            </div>
            </div>

            <div className="project3">
            <div className="animate__animated animate__slideInRight">
            <div className="projectImage">
              <img src={pro3} alt="image" />
              <div>
              <h1>PixelCola</h1>
            <p>Python Flask application connected to a SQL-Alchemy database displayed as a gaming application that encourages users to play three different mini games about CyberSecurity terminology and principles</p>
            
            <br />
            <div className="pro-btn2s">
                <button className="btn" onClick={()=>(downloadFileAtURL(BINPACKING_ZIP_FILE_URL))}>Download Zip File</button>
            </div>
              </div>
              </div>
            </div>
            </div>
            
            </div>
            <h1 className="project-heading">Here are some of my most recent projects written in the following languages: C++, C, Java, JavaScript, Python, PHP and Lisp. These projects all display and demonstrate various important Computer Science topics such as the TCP Client-Server relationship, SQL Database creation and management, Finite State Machines, GUI creation, Thread computing as well as other various data structures and algorithms </h1> 
            
            <div className="slideContain">
                <Slider {...sliderSettings}>
            {WorkCardData.map((val,ind) =>{
                return(
                    <WorkCard 
                    key ={ind}
                    imgsrc={val.imgsrc}
                    title={val.title}
                    text={val.text}
                    view={val.view}
                    />
                );
            })}
            </Slider>
            </div>
       </div>
     </div>



     
    );
};

export default Work;