import "./FormStyles.css"
import React, {useRef} from "react";
import emailjs from '@emailjs/browser';


const Form = () =>{ 
    const contact = useRef()
    
        const sendEmail = (e) => {
            e.preventDefault();
        
            emailjs.sendForm('service_4nw46ux', 'template_59i6tfs', contact.current, 'Sc34hcJr8_lqimxMm')
              .then((result) => {
                  console.log(result.text);
              }, (error) => {
                  console.log(error.text);
              });
              e.target.reset()
          };
        
    
          return (
            <div className="containForm">
             
            <form ref={contact} onSubmit={sendEmail}>
           
              <label>Name</label>
              <input type="text" name="user_name" />
              <br />
              <br />
              <label>Email</label>
              <input type="email" name="user_email" />
              <br />
              <br />
              <label>Message</label>
              <textarea name="message" />
             
              <button className ="btn" type="submit">Send Email</button>
            </form>
            </div>
          );
};

export default Form;