import "./AboutContentStyles.css";
import React from "react";
import {Link} from "react-router-dom";
import React1 from "../assets/suitPicture.jpeg";
import React2 from "../assets/GradPic_XLarge.jpg";
//import React1 from "../assets/c2.jpeg"
//import React2 from "../assets/java3.png"
import Resume from "./Resume";

const resume = "AlexanderReichelResume.pdf"
const coverLetter = "AlexReichelCoverLetter.pdf"

const downloadFileAtURL = (url) => {
    fetch(url)
    .then((response)=>response.blob())
    .then((blob)=> {
        const blobURL = window.URL.createObjectURL(new Blob([blob]))
        const fileName = url.split("/").pop();
        const aTag = document.createElement("a");
        aTag.href = blobURL;
        aTag.setAttribute("download" , fileName);
        document.body.appendChild(aTag);
        aTag.click();
        aTag.remove();
    });

};

// npm install @material-ui/core  --legacy-peer-deps  
const AboutContent = () =>{ 
    return (
      
        <div className="about">
            
            <div className="left">
                <div className="animate__animated animate__bounceInLeft animate__delay-1s">
      
                    <Resume />
                </div>
                
                </div> 

            <div className="right">

            <div className="animate__animated animate__backInRight animate__delay-1s">

                <div className="buttons">
                   
                    <button className="btn" onClick={()=>(downloadFileAtURL(resume))}>Download Resume</button>
                    <button className="btn" onClick={()=>(downloadFileAtURL(coverLetter))}>Download Cover Letter</button>
                    <a href="https://github.com/AlexReichel02?tab=repositories">
                    <button className="btn">GitHub</button></a>


                </div>

<br />
<br />
        <div className="image-container">

                <div className="imgTop">
                <img src={React2} className = "img" alt="true" />
                </div>
                <div className="imgBottom">
                <img src={React1} className = "img" alt="true" />
            </div>

        </div>

        </div>

    </div>
    </div>
    );
    
};

export default AboutContent;