import pro1 from "../assets/proj1.avif"
import pro2 from "../assets/proj2.avif"
import pro3 from "../assets/proj3.avif"
import pic4 from "../assets/pic4.jpg"
import pic5 from "../assets/pic5.jpg"
import pic6 from "../assets/pic6.jpg"

const BINPACKING_ZIP_FILE_URL = "BinPackingGreedyAlgorithm.zip";
const CLIENTSERVERDB_ZIP_FILE_URL = "ClientServerDataBase.zip";
const DB_ZIP_FILE_URL = "DataBase.zip";
const HASHTABLEENCRYPT_ZIP_FILE_URL = "HashTableEncryption.zip";
const HORSERACE_ZIP_FILE_URL = "HorseRace.zip";
const HTTPSERVER_ZIP_FILE_URL = "HttpServer.zip";
const MYSHELL_ZIP_FILE_URL = "MyShell.zip";
const SPELLCHECKER_ZIP_FILE_URL = "SpellChecker.zip";
const TICTAC_ZIP_FILE_URL = "TTT.zip";
const TRAVELING_ZIP_FILE_URL = "TravelingSalesman.zip";
const LCS_ZIP_FILE_URL = "TwoLCSMethods.zip";
const FSAPython_ZIP_FILE_URL = "PythonFSA.zip";
const ClientSideCode_ZIP_FILE_URL = "ClientSideCode.zip";
const FSALisp_ZIP_FILE_URL = "FSALisp.zip"
const PHPDatabase_ZIP_FILE_URL = "PHP_Database_query.zip"
const PythonLispFSA_ZIP_FILE_URL = "PythonCreatesFSALisp.zip"
const LizardCrossing_ZIP_FILE_URL = "LizardCrossing.zip"
const ProjectLogin_ZIP_FILE_URL = "ProjectLogin.zip"
const WebsiteScraper_ZIP_FILE_URL = "WebsiteScraper.zip"
const PythonSearchEngine_ZIP_FILE_URL = "PythonSearchEngine.zip"
const PythonChatBot_ZIP_FILE_URL = "PythonChatBot.zip"

const ProjectCardData = [
    {
        imgsrc: pro3,
        title: "C++ HTTP Server",
        text : "This C++ project creates a simple HTTP server. This project represents knowledge of the TCP/IP Protocol between a socket server and client acting as a browser.",
        view: HTTPSERVER_ZIP_FILE_URL,
    },
    {
        imgsrc: pic6,
        title: "PHP User Authentication System connected to MySQL Database",
        text : "PHP script that functions as User Authentication System allowing any user to login or register. The Script creates and populates a MySQL database and table with user information, applicable to any company needing user authentication.",
        view: ProjectLogin_ZIP_FILE_URL,
    },
    {
        imgsrc: pic5,
        title: "Python Finite State Automata Machine Generator",
        text : "This Python project reads a txt file containing the specifics required to build a finite state machine, the program then builds a fully functional finite state machine that is able to process input strings. After the strings have been processed the user is then presented a graphic of the FSA.", 
        view: FSAPython_ZIP_FILE_URL ,
    },
    {
        imgsrc: pro1,
        title: "JavaFX HorseRacing with Threads",
        text : "This JavaFX project utilizes Threads and JavaFX to display horses racing as threads. This project creates 6 threads that act as horses,the user is then able to race,reset or quit the horse race.",
        view: HORSERACE_ZIP_FILE_URL ,
    },
    {
        imgsrc: pro3,
        title: "C++ Client and Server TicTacToe",
        text : "This C++ project allows any number of users to connect to a Tic Tac Toe server that plays against the user with random automated moves until the user exits.",
        view: TICTAC_ZIP_FILE_URL,
    },
    {
        imgsrc: pic4,
        title: "JavaFX  SpellChecker",
        text : "This Maven JavaFX project acts a graphical editor allowing a user to spellcheck words as well as save or open documents.",
        view: SPELLCHECKER_ZIP_FILE_URL,
    },
    {
        imgsrc: pro2,
        title: "Python Website Scraper",
        text : "This Python program uses TK canvas to present a UI for the user to insert the link of any website, then proceeds to scrape all text from the website, the keywords of the website and the AI generated summary of the website.",
        view: WebsiteScraper_ZIP_FILE_URL,
    },
    {
        imgsrc: pic5,
        title: "Python Search Engine",
        text : "This Python program emulates a search engine via flask terminal that allows for the user to enter anything searchable, then the program computes optimized search results",
        view: PythonSearchEngine_ZIP_FILE_URL,
    },
    {
        imgsrc: pro1,
        title: "Python Language Translator with Native Voice sound",
        text : "This Python program uses TK canvas to present a UI for the user to enter any source and destination language as well as the message to be translated. The program then allows the user to select a native voice for the program to pronounce the translation. The program also has a AI ChatBot attached for the user to enter any questions",
        view: PythonChatBot_ZIP_FILE_URL,
    },
    {
        imgsrc: pic4,
        title: "Lisp Finite State Automata Machine",
        text : "This Lisp program creates a Finite State Automata Machine from a list of hard coded values, then reads a text file containing a string that will either be evaluated as legal or illegal.",
        view: FSALisp_ZIP_FILE_URL ,
    },
   
    {
        imgsrc: pic6,
        title: "PHP script that creates and generates MySQL Database",
        text : "PHP program creates and populates a database with a database table from a CSV file containing information. Once the database table has been created, users can click a button that directs them to page that allows them to issue different queries.",
        view: PHPDatabase_ZIP_FILE_URL,
    },
    {
        imgsrc: pro1,
        title: "Finite State Automata Machine Generator in Python converted to Lisp",
        text : "This Python project reads a file containing values for a Finite State Automata machine, The python program then generates a fully functional Lisp program that is able to evaluate strings from the python program's values.",
        view: PythonLispFSA_ZIP_FILE_URL ,
    },
    {
        imgsrc: pic5,
        title: "C++ Bin Packing Greedy Algorithm",
        text : "This C++ project is able to demonstrate the results of a bin packing problem via a Offline and Online algorithm using First Fit, Best Fit, and Next Fit.",
        view: BINPACKING_ZIP_FILE_URL,
    },
    {
        imgsrc: pic6,
        title: "C++ Shell Program",
        text : "This C++ project implements a simplified shell that parses a command entered by the user, creates new processes to execute specific programs and performs input and output redirection and background execution.",
        view: MYSHELL_ZIP_FILE_URL,
    },
    {
        imgsrc: pro2,
        title: "Java FX Client and Server with Database",
        text : "This Java FX project contains both a client and server program. The Server folder contains the database, the client then connects to the server and is able to make choices from a database via a Java FX menu.",
        view: CLIENTSERVERDB_ZIP_FILE_URL,
    },
    {
        imgsrc: pro3,
        title: "Java Database created from Reflection",
        text : "This Java program refactors to improve structure, work with the Derby database and the Java Reflection API, automate database creation from class definitions and instances.",
        view: DB_ZIP_FILE_URL,
    },
    {
        imgsrc: pic6,
        title: "C++ Client Side Code able to join any Server",
        text : "This C++ client side code prompts the user to enter the port and host name of a server, if the user enters the correct server information then the user is able to access any file on the server.",
        view: ClientSideCode_ZIP_FILE_URL ,
    },
    {
        imgsrc: pro1,
        title: "C++ HashTable Encryption",
        text : "This C++ project creates an efficient storage and lookup scheme for userids and passwords utilizing an externally chained hash table. ",
        view: HASHTABLEENCRYPT_ZIP_FILE_URL,
    },
    {
        imgsrc: pro2,
        title: "C++ Lizard Thread Crossing",
        text : "This C++ project (*Linux Only*) creates multiple lizard threads that attempt to cross a river within a time slot so that they are not eaten by the cat thread.This project relies on mutex locks to protect critical sections and threads experiencing a semaphore with function calls such as sem_post and sem_wait.",
        view:  LizardCrossing_ZIP_FILE_URL,
    },
    {
        imgsrc: pic5,
        title: "C++ Brute Force of Traveling Salesman",
        text : "This C++ project calculates the fastest tour between cities separated by values using the Brute force Method in order to find the most optimal route.",
        view:  TRAVELING_ZIP_FILE_URL,
    },
    {
        imgsrc: pic6,
        title: "C++ Two Longest Common Subsequence Methods",
        text : "This C++ project displays the Longest Common Subsequence of any two words using two different methods.",
        view: LCS_ZIP_FILE_URL,
    },

];
export default ProjectCardData