import "./FooterStyles.css"
import {Link} from "react-router-dom"
import React from "react";
import {FaGithub,FaLinkedin} from "react-icons/fa";
import SocialMediaMenu from "./SocialMediaMenu";
const Footer = () =>{ 
    return(
     <div className="footer"> 
        <div className="footer-container">

            <p>Now that you know a little more about me, If you are interested in any of my abilities and desire to work together please do so by contacting me anytime.</p>
            <p>Tap the Button below to contact me via any social media.</p>
            <div className="menu">
            <SocialMediaMenu />
            </div>
         
        </div>
    </div>
    )
};

export default Footer