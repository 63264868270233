import  InstagramIcon from "@material-ui/icons/Instagram";

export default {
    name: "Alex Reichel",
    title: "Software Engineer",
    birthday : "23rd August 2002",
    email: "reichela76@gmail.com",
    phone: "850 428 1212",

    socials: {
            instagram :{
            link: "https://www.instagram.com/alexreichell/?hl=en",
            text: "Alex Reichel",
            icon: <InstagramIcon />,
        },
    },

        experience: "Experience",
        education: "Education",
	    skill1: "Efficient with Microsoft Office, Visual Studio Code, Eclipse, Visual Studio, Github and Azure",
	    skill2: "Created solutions in C++, C, C#, Python, Java, React.js, JavaScript, PHP, MySQL, HTML, CSS and WordPress",
        skill3: "Ability to adapt and demonstrate flexibility when problems occur",
        skill4: "Detail oriented and solution driven",
        skill5: "Communicates appropriately and professionally to others to finish tasks",
       

    about: 
    "Motivated and enthusiastic Software Engineer with an interest and experience in Full Stack Development. Proven experience in developing software and well-skilled in the software development life cycle. Interested in Problem solving and logical thinking.",

    experiences: [
        {
            title:"Software Engineer at Dynamic Software Solutions",
            company: "Dynamic Software Solutions",
            date: "December 2023 - Present",
            description1:"Collaborated on four different desktop GeoSpatial WPF applications in C# within the .NET Framework, managing SQL Server databases",
            description2:"Implemented advanced front-end features using XAML, enhancing functionality by extracting data through XML and JSON serialization/deserialization",
            description3:"Configured hardware/software for seamless integration and deployment of applications",
            description4:"Demonstrated proficiency in back-end feature development, creating solutions dependent on querying and updating data from SQL databases",        
        },
        {
            title:"Software Engineer Intern at Dynamic Software Solutions",
            company: "Dynamic Software Solutions",
            date: "May 2023 - December 2023",
            description1:"Developed front-end features for a C# program in the .NET framework, serving as a product tracker for company and customer order information linked to a SQL database",
            description2:"Led the design and deployment of a company website with interactive web pages using WordPress, accessible at http://www.geoexpt.com/",
            description3:"Executed tasks from the product backlog, including fixing unit tests, debugging, troubleshooting, and integrating new data models into existing software",
            description4:"Utilized Scrum Agile Methodology in daily work, conducting Daily Scrum Meetings, Planning/Review meetings, managing Sprint Backlog, and having 1-1 meetings. Developed and updated solutions using Azure DevOps to align with business needs",
        },
        {
            title:"Software Developer at Smart Group Traders Inc",
            company: "Smart Group Traders Inc",
            date: "May 2023 - August 2023", 
            description1:"Developed company's data transfer software, automating the process and reducing transfer time from 30 minutes to 30 seconds",
            description2:"Constructed and managed a Python program with a user interface for translating Spanish words from PDF documents, extracting essential data, and formatting it into automated Excel sheets using Python packages and APIs (deep-translator, xlwings, xlsxWriter, pdfminer)",
            description3:"Maintained open communication with clients to meet business expectations, showcasing flexibility and persistence in resolving issues"
        
           
        },
    ],

    educations: [
        {
            title: "University of West Florida",
            location: "Pensacola, Florida",
            date : "August 2020 - December 2023",
            description: "Graduated with Bachelor of Science in Computer Science with Honors: Cum Laude (GPA: 3.66) | Dean’s List (2020, 2021, 2023) | President's List (2023) | UWF Cybersecurity Certificate | CompTIA Security+ Certificate",
        },
    ]
};