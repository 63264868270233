import React, { useState } from 'react';
import './SocialMediaMenu.css'; // Create this CSS file to style your component
import { FaDiscord, FaWhatsapp, FaTwitter, FaReddit, FaLinkedin, FaInstagram, FaGithub, FaYoutube,FaRegHandshake } from "react-icons/fa";

function SocialMediaMenu() {
  const [isActive, setIsActive] = useState(false);

  const toggleMenu = () => {
    setIsActive(!isActive);
  };

  const socialMediaLinks = [
    { icon: <FaInstagram />, link: 'https://www.instagram.com/rrreichel/?hl=en' , color: 'white'},
    { icon: <FaDiscord />, link: 'https://discordapp.com/users/AlexReichel#3875' ,color: 'navy'},
    { icon: <FaLinkedin />, link: 'https://www.linkedin.com/in/alexanderreichel/', color: '#0a66c2' },
   
    { icon: <FaGithub />, link: 'https://github.com/AlexReichel02', color: 'white' },
    { icon: <FaTwitter />, link: 'https://www.twitter.com' , color: '#1da1f2' },
  ];

  return (
    <div className={`menu ${isActive ? 'active' : ''}`}>
      <div className="toggle" onClick={toggleMenu}>
      <FaRegHandshake size={100} style={{color: "green" }} />
      </div>
     <ul>
     {socialMediaLinks.map((link, index) => (
      
          <li
            key={index}
            style={{ '--i': index, '--clr': link.color }}
          >
            <a href={link.link}>
              {link.icon}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default SocialMediaMenu;

