import { Grid, Typography } from "@material-ui/core";
import React, {useState} from "react";
import "./Resume.css";
import resumeData from "./resumeData";
import CustomTimeline, { CustomTimelineSeperator } from "./Timeline";
import { TimelineContent, TimelineItem } from "@material-ui/lab";
import  WorkIcon from "@material-ui/icons/Work";
import  SchoolIcon from "@material-ui/icons/School";




const Accordion = ({ title,date,content }) => {
    const [isActive, setIsActive] = useState(false);

    return (
        <div className="skillSection" >
            <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
                <h2 className="sectionAbout" >{title}</h2>
                <h2 className="sectionAbout">{date} </h2>
                <h2 className="view"> {isActive ? '^' : 'View'}</h2>
            </div>
           
            {isActive && content.map((item, index) => (
               
                <h2 className="sectionAbout2" key={index}>
                    {item}
                </h2>
            ))}
        </div>
    );
};


const Resume = () => {
    return (
        <>
      
        <Grid container className="section pb_45">
            <Grid item className="section_title_mb_30">
      
            </Grid>
            <Grid item xs={12}>
            <h2 className="section_title_text">Who Am I ?</h2>
            <h2 className="sectionAbout">{resumeData.about}</h2>
             </Grid>
        </Grid>


                <Grid container className="section">
                    <Grid item className="section_title mb_30">
                        <span></span>
                        <br />
                        <h2 className="section_title_text">Resume</h2>
                        <br />
                        <div className="skillSection">
                        <h2 className="section_title_text2">Skills:
                        <br/ >
                        <br/ >
                        <h3>{resumeData.skill1}</h3>
                        <h3>{resumeData.skill2}</h3>
                        <h3>{resumeData.skill3}</h3>
                        <h3>{resumeData.skill4}</h3>
                        <h3>{resumeData.skill5}</h3>
                       
                       
                        </h2>
                        </div>
                        </Grid>
                       

                     
                            <Grid container>
                            <br />
                        <br />
                            <CustomTimeline title = {resumeData.education} icon ={<SchoolIcon />}>
                                        {resumeData.educations.map((education)=> (
                                            
                                            <TimelineItem>
                                                <CustomTimelineSeperator />
                                                <TimelineContent className="timeline_content">
                                                  
                                                <div className="skillSection">
                                                <h2 className="sectionAbout"> {education.title}</h2>
                                                   
                                                <h2 className="sectionAbout"> {education.date}</h2>
                                                 
                                                <h2 className="sectionAbout2">  {education.description}</h2>
                                               </div>
                                           
                                                  
                                                </TimelineContent>
                                            </TimelineItem>
                                        ))}
                                    </CustomTimeline>
                                    <br />
                        <CustomTimeline  title = {resumeData.experience} icon ={<WorkIcon  />}>

                             {resumeData.experiences.map((experience) =>(
                                 <div className="accordion">
                                    <TimelineItem>
                                        <CustomTimelineSeperator />
                                        <TimelineContent>

                                
                                                
                                                   

                                         <Accordion title={experience.title} date = {experience.date} content={[experience.description1,experience.description2,experience.description3,experience.description4,experience.description5]} />
                                             
                               
                                        
                                         
                                        </TimelineContent>
                                    </TimelineItem>
                                    </div>
                            ))}
                        </CustomTimeline>
                    </Grid>
                        
                
                   
                                    
                   
                </Grid>
         
        </>
    );
};

export default Resume;